import {
  AssetsProvider,
  Link,
  styled,
  useQuery,
  useTranslation,
} from '@obvio/app'
import { RichText } from '@obvio/template'
import { Stack } from '@obvio/ui'

import {
  Divider,
  GridWidth,
  Img,
  RelativeStack,
  Title,
} from './RoomsDetailed/components'
import { getImage } from '../utils'
import { ReadMoreLink } from '@/components/ReadMoreLink'
import { QUERIES } from '@/constants/queries'

import type { ReactElement } from 'react'

type PackagesDetailedProps = {
  ids: string[]
}

const PackageLinkWrap = styled(Link)`
  &:hover * {
    color: #636a51;
  }
`

export function PackagesDetailed({ ids }: PackagesDetailedProps): ReactElement {
  const { t } = useTranslation()
  const { data: offers } = useQuery('getProfitRoomOffersIds', {
    args: {
      ids,
    },
    select: QUERIES['packages-carousel'].getProfitroomOffers,
  })

  const count = offers?.length ?? 0

  return (
    <Stack kind="vertical" spacing="extraLarge">
      <GridWidth
        gap="medium"
        templateColumns={`repeat(${count}, minmax(0, 1fr))`}
      >
        {offers?.map((offer) => (
          <AssetsProvider key={offer.id} path={`/offer/${offer.id}`}>
            <RelativeStack kind="vertical" spacing="medium">
              <Img img={getImage(offer.image)} />
              <Divider />
              <PackageLinkWrap href={`/pakiety/${offer.slug}`}>
                <Stack kind="vertical" spacing="medium">
                  <Title tag="h3" className="LE-text">
                    {offer.title}
                  </Title>
                  <div>
                    <RichText value={offer.description ?? ''} />
                  </div>
                </Stack>
              </PackageLinkWrap>
            </RelativeStack>
          </AssetsProvider>
        ))}
      </GridWidth>
      <ReadMoreLink center href="/pakiety">
        {t('label.browseAllPackages')}
      </ReadMoreLink>
    </Stack>
  )
}
